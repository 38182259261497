<template>
  <div style="display: contents">
    <nav class="nav_style">
      <a :href="jumpSubdomain && whereFrom == 2 ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): ''))" class="logo_style" @click="goHome">
        <img :src="logo" alt="">
<!--        {{ $originCopyWriting }}-->
      </a>
      <div class="home_box">
        <a :href="jumpSubdomain && whereFrom == 2 ? (newJumpUrl + (urlPCType ? '/Pc' : '/P') + '/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')) : ((urlPCType ? '/Pc' : '/P') + '/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): ''))" class="home_style" @click="goHome"><img :src="home" alt=""></a>
        <button class="sousuo_style sousuo_style_1" @click="searchClick">
          <img :src="souSuo" alt="">
        </button>
      </div>
    </nav>
    <SearchFor :UnfoldAndCollapse="UnfoldAndCollapse" v-if="UnfoldAndCollapse" :whereFrom="whereFrom" :portal="portal" :gameName="gameName"  @searchClick="searchClick"/>
  </div>
</template>

<script>
import logo from '/public/img/logos/defaultLogo.png'
import home from '@/assets/home.png';
import souSuo from '@/assets/sousuo.png';

import SearchFor from '@/components/SearchFor.vue';
import {clickSearchLog,pageOutLog,detailsPageOutLog} from "@/utils/utils";
export default {
  name: "PCLogo",
  props: ["whereFrom","portal","gameName"], // whereFrom 1首页 2详情页
  components: {
    SearchFor
  },
  data() {
    return {
      logo: this.$originCopyWriting == 'hahamini' ? `${window.location.origin}/img/logos/hahaminiLogo.png` :
      this.$originCopyWriting == 'kkplay' ? `${window.location.origin}/img/logos/kkplayLogo.png` :
          this.$originCopyWriting == 'playus' ? `${window.location.origin}/img/logos/playusLogo.png` :
          this.$originCopyWriting == 'oppogame' ? `${window.location.origin}/img/logos/oppogameLogo.png` :
          this.$originCopyWriting == 'h5joy' ? `${window.location.origin}/img/logos/h5joyLogo.png` :
          this.$originCopyWriting == 'joymarvel' ? `${window.location.origin}/img/logos/joymarvelLogo.png` :
          this.$originCopyWriting == 'gamesinall' ? `${window.location.origin}/img/logos/gamesinallLogo.png` :
          this.$originCopyWriting == 'ajoy' ? logo :
          this.$originCopyWriting == 'agame' ? logo :
          this.$originCopyWriting == 'zzboom' ? logo :
          this.$originCopyWriting == 'gdgame' ? logo :
          this.$originCopyWriting == 'qagame' ? logo :
          this.$originCopyWriting == 'yamat' ? logo :
              this.$originCopyWriting == 'kikifun' ? logo :
                  this.$originCopyWriting == 'wannagame' ? logo :
                  this.$originCopyWriting == 'h5gamesfun' ? logo :
                  this.$originCopyWriting == 'h5gamesplay' ? logo :
                              `${window.location.origin}/img/logos/makefunLogo.png`,
      home,
      souSuo,
      UnfoldAndCollapse: false, // 展开收起
      urlPCType: false, // 跳转路径为Pc
      newJumpUrl: null,
      jumpSubdomain: null,
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { urlPCType, jumpSubdomain } = channelInfo || {}
    this.urlPCType = urlPCType
    this.jumpSubdomain = jumpSubdomain
    this.newJumpUrl = window.location.protocol + '//' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  methods: {
    goHome() {
      if (this.whereFrom == 2) {
        pageOutLog(this.portal)
        detailsPageOutLog(this.portal, this.gameName)
      } else {
        pageOutLog(this.portal)
      }
    },
    // 点击搜索
    searchClick() {
      this.UnfoldAndCollapse = !this.UnfoldAndCollapse
      // 点击搜索打点
      let portal = this.portal
      clickSearchLog(portal)
    },
  }
}
</script>

<style lang="less" scoped>
a{
  text-decoration: none;
  color: #000000;
}
@media (min-width: 1871px) {
  .nav_style {
    --offset: -927px !important;
    width: 204px !important;
    flex-direction: row !important;
  }

  .logo_style {
    height: 50px !important;
    //margin: -2px auto 0px !important;
    font: 700 20px "SHOWG", sans-serif!important;
    line-height: 42px!important;
  }

  .home_box {
    border-top: 0px !important;
    border-left: 2px solid #f0f5fc !important;
    flex-direction: column !important;
    height: 100% !important;
    width: 46px !important;
    border-radius: 0px 16px 16px 0px !important;
    .home_style {
      border-right: 0px !important;
      border-bottom: 1px solid #f0f5fc !important;
      height: 50%!important;
      width: 100%!important;
    }
    .sousuo_style_1 {
      border-left: 0px!important;
      border-top: 1px solid #f0f5fc!important;
      height: 50%!important;
      width: 100%!important;
    }
  }
}

@media (min-width: 1541px) and (max-width: 1870.9px) {
  .nav_style {
    --offset: -762px !important;
  }
}

@media (min-width: 1321px) and (max-width: 1540.9px) {
  .nav_style {
    --offset: -652px !important;
  }
}

@media (min-width: 1211px) and (max-width: 1320.9px) {
  .nav_style {
    --offset: -597px !important;
  }
}

@media (min-width: 991px) and (max-width: 1210.9px) {
  .nav_style {
    --offset: -490px !important;
  }
}

@media (min-width: 111px) and (max-width: 990.9px) {
  .nav_style {
    --offset: -380px !important;
  }
}

@media (max-width: 775px) {
  .nav_style {
    --offset: 3px !important;
    left: 0 !important;
  }
}
.nav_style {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 16px;
  background: rgb(255, 255, 255);
  box-shadow: 0 6px 32px 0 rgba(0, 0, 0, .24);
  border-radius: 16px;
  width: 94px;
  height: 94px;
  flex-direction: column;
  --left: 50%;
  left: var(--left);
  --offset: -487px;
  transform: translate(var(--offset), 0);

  .logo_style {
    //width: 80px;
    height: 38px;
    margin: 11px auto 7px;
    font: 700 14px "SHOWG", sans-serif;
    line-height: 30px;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo_style:hover{
    transform: scale(1.05);
  }
  .home_box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #f0f5fc;
    width: 100%;
    height: 40px;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
    .home_style {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      border-right: 1px solid #f0f5fc;
    }
    .home_style:hover{
      background: #f0f5fc;
    }
    .sousuo_style_1{
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      border-left: 1px solid #f0f5fc;
      text-indent: -200vw;
      font-size: 0px;
      background: white;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .sousuo_style_1:hover{
      background: #f0f5fc;
    }
    .sousuo_style {
      font-size: 100%;
      font-family: inherit;
      border: 0px;
      padding: 0px;
      background: none;
      cursor: pointer;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
}
</style>