<template>
  <div v-title :data-title="`Online Games on ${$headToUpperCase} ——Let's play`" id="homeId">
    <div v-if="contentType">
      <div>
        <AppList :appGameList="appGameList"></AppList>
      </div>
      <div class="shortcut-popup-desktop" v-if="$store.state.deferredPromptType && $store.state.installType == 1 && $originCopyWriting != 'kikifun'">
        <div class="shortcut-popup-desktop__description">
          <p class="shortcut-popup-desktop__text">Add a quick start icon for {{ this.$headToUpperCase }}！</p>
        </div>
        <div class="shortcut-popup-desktop__buttons">
          <el-button @click="After">Later</el-button>
          <el-button @click="addToDesktop">Add</el-button>
        </div>
      </div>
      <StartAndEnd portal="mobile_home" />
    </div>
    <div style="display: contents" v-if="smegmaType">
      <div class="masking masking_1" style="background-color: #AEEBFE">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import StartAndEnd from "@/components/MobileTerminal/MobileHome/StartAndEnd";
// import TopBox from "@/components/MobileTerminal/MobileHome/TopBox";
import AppList from "@/components/MobileTerminal/MobileHome/AppList";

import {determinePcOrMove, pageInitLog, pageOutLog} from '@/utils/utils.js'
import bgImg from '@/assets/advertisement/bg.jpg';
import btnBg from '@/assets/advertisement/btn_anniu.png';
import wz from '@/assets/advertisement/wz.png';
import bg2 from '@/assets/advertisement/bg2.png';
import gamepad from '@/assets/gamepad.png'
export default {
  name: "mobileIndex",
  data() {
    return {
      allGameList: [], // 全部游戏
      appGameList: [], // 中间游戏列表
      gameTypeList: [], // 底部游戏类型
      logoutCount: 0, // 长时间未操作
      timerDate: null, // 定时器
      advBg: {
        backgroundImage: `url(${bg2})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        minHeight: '300px'
      },
      bg: {
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      btnBg: {
        backgroundImage: `url(${btnBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      },
      count: 5, // 倒计时按钮
      countText: 'CLOSE', // 倒计时按钮
      timerCountdown: null, // 倒计时
      count2: 5, // 倒计时按钮
      timerCountdown2: null, // 倒计时
      wz,
      smegmaType: false,
      contentType: false,
      gamepad
    }
  },
  components: {
    StartAndEnd,
    AppList,
  },
  created() {
    if (localStorage.getItem('installCancelTime')) {
      if (this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).isAfter(this.$dayjs(localStorage.getItem('installCancelTime')))) {
        this.$store.commit("changeInstallType",'1')
        localStorage.setItem('installType','1')
        localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
      } else {
        this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
      }
    } else {
      this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
    }
  },
  mounted() {
    if (determinePcOrMove() == 2) {
      let { channel } = this.$route.query
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { urlPCType } = channelInfo || {}
      this.$router.push({
        path: `${urlPCType ? '/Pc' : '/P'}/homeIndex`,
        query: {
          channel
        }
      },()=>{})
    } else {
      this.smegmaType = true
      // 进入页面埋点
      pageInitLog('mobile_home')

      this.timer = setInterval(()=>{
        if (window.loadOk) {
          clearInterval(this.timer)
          this.smegmaType = false
          this.contentType = true
          let jsonArr = getAllJson() || [] // 原数组
          let allGameList = []
          jsonArr && jsonArr.map((item)=>{
            allGameList.push(item)
          })
          this.allGameList = allGameList
          // clearInterval(this.timerDate)
          // this.timer(allGameList)
          let arr = allGameList || [] // 原数组
          let newArr = [] // 新数组
          let num = Math.ceil(arr.length / 12)
          console.log(num);
          for ( let i = 1; i <= num; i++ ) {
            if (i == 1) {
              newArr[i - 1] = arr.splice(0,11)
            } else {
              newArr[i - 1] = arr.splice(0,12)
            }
          }
          console.log(newArr);
          this.appGameList = newArr
        }
      },2000)
    }
  },
  methods: {
    addToDesktop() {
      console.log(this.$store.state.deferredPrompt);
      this.$store.state.deferredPrompt && this.$store.state.deferredPrompt.prompt();
      this.$store.commit("changePWA",{deferredPrompt: null,deferredPromptType: this.$store.state.deferredPromptType})
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      this.$store.commit("changeInstallPageClick",'3')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    },
    After() {
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    }
  },
  // beforeRouteLeave(to, from, next){
  //   console.log(to);
  //   console.log(from);
  //   if (to.query.gameId) {
  //     getAllJson().map((item)=>{
  //       if (item.gameId == to.query.gameId) {
  //         clickGameLog('mobile_home', item)
  //         recentGame(item)
  //         if (this.$originCopyWriting == 'ajoy') {
  //           bge && bge('event', 'button', {configId: '903985143796275456'});
  //           sessionStorage.setItem('pixelType','1')
  //         }
  //       }
  //     })
  //   }
  //   next()
  // },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog('mobile_home')
  }
}
</script>

<style lang="less" scoped>
.top-text{
  text-align: center;
  padding: 10px 5px 5px;
  margin-top: 2.5555rem;
  h1{
    width: 100%;
    font-size: 14px;
    color: #fff;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
  }
}
#homeId{
  width: 100%;
  height: auto;
  position: relative;
  @keyframes djqRmU{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @keyframes etkMLf{
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .masking_1{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
    z-index: 100001;
  }
  .masking{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 18px;
      height: 18px;
      margin: 0 2px;
      border-radius: 100%;
      background: white;
      animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
    }
    div:nth-child(2){
      animation-delay: -0.16s;
    }
  }
  .shortcut-popup-desktop{
    background: #FFFFFF;
    border-radius: 18px;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 16px 62px rgba(3,21,48,.15);
    color: #222222;
    padding: 20px;
    position: fixed;
    right: 24px;
    z-index: 997;
    width: 80%;
    .shortcut-popup-desktop__description{
      .shortcut-popup-desktop__text{
        word-wrap: break-word;
        --light-cyan-100: #222222;
        box-sizing: border-box;
        color: var(--light-cyan-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        padding-right: 0;
      }
    }
    .shortcut-popup-desktop__buttons{
      margin-top: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      .el-button{
        width: 130px;
        background: #f0f0f0;
        border: none;
        color: #6f6f6f;
        font-size: 15px;
        font-weight: 600;
      }
      .el-button:nth-child(2){
        background: #198cff;
        color: #e9f2ff;
        margin-left: 20px;
      }
    }
  }
}
</style>
